import { Camera } from "./babylon";
import { Color3, Color4, Matrix, Vector2, Vector3 } from "./babylon";
import { CreateDisc } from "./babylon";
import { CreatePlane } from "./babylon";
import { CreateTorus } from "./babylon";
import { CustomMaterial } from "./babylon";
import { DirectionalLight } from "./babylon";
import { Engine } from "./babylon";
import { Layer } from "./babylon";
import { Scene } from "./babylon";
import { SceneLoader } from "./babylon";
import { ShaderMaterial } from "./babylon";
import { ShadowDepthWrapper } from "./babylon";
import { ShadowGenerator } from "./babylon";
import { StandardMaterial } from "./babylon";
import { Texture } from "./babylon";
import { UniversalCamera } from "./babylon";

import overlayMaskVertexShaderSource from "./overlayMask.vertex.fx?raw";
import overlayMaskFragmentShaderSource from "./overlayMask.fragment.fx?raw";

export class IPSCore {

	/** @protected @type {Texture} */
	_maskImage;

	/** @protected @type {Texture} */
	_lightAndShadowMap;

	/**
	 * Creates a new IPSCore instance for the specified canvas.
	 * <p>
	 * It's possible to use a higher internal resolution by setting the canvas
	 * width and height to a value that is bigger than the CSS width and height.
	 * However, the internally used Scene.InputManager class does not support this
	 * behavior and picking will not work correctly as the PointerEvent coordinates
	 * are not scaled accordingly. A workaround is provided in this class to
	 * dynamically patch the InputManager.
	 * @param {string} canvasId the ID of the canvas
	 * @param {boolean} wantInternalResolution set to true in order to make it
	 * 		possible to use a different internal rendering resolution.
	 */
	constructor(canvasId, wantInternalResolution) {
		/** @public @type {HTMLElement} */
		this.canvas = document.getElementById(canvasId);
		/** @public @type {Engine} */
		this.engine = new Engine(this.canvas, true, {
			preserveDrawingBuffer : true,
			stencil : true
		});
		
		/** @type {HTMLElement} */
		const canvas = this.engine.getRenderingCanvas();

		/** @protected @type {Vector3} */
		this.startingPoint;

		/** @protected @type {number} */
		this._currentRotation = 0;

		/** @protected @type {number} */
		this._rotOrMove = 0;

		/** @protected @type {number} */
		this._srot = 0;

		/** @protected @type {Mesh[]} */
		this._emeshes = [];

		/** @protected @type {boolean} */
		this._activateRotationHandleFadingTimer = true;

		/**
		 * The interval ID for the fading delay function.
		 * @protected @type {number}
		 */
		this._fadingActivationTimer;

		/**
		 * Contains the amount of idle cycles that have passed. If a threshold is exceeded, then
		 * a fade function will be invoked.
		 * @protected @type {number}
		 */
		this._fadingActivationIdleTime = 0;

		/**
		 * Defines the next expected type of fading. If this is set to 0, then a "fade in" will occur
		 * after the idle time has passed. If 1, then a "fade out" will start.
		 * @protected @type {number}
		 */
		this._fadingActivationType = 0;

		/** @protected @type {number} */
		this._fadeVal = 0.1;

		/**
		 * The interval ID for the fading function.
		 * @protected @type {number}
		 */
		this._fader;

		/** @protected @type {number} */
		this._layerBackTexNum = 1; // TODO this is part of the old layer system and may be removed in the future

		/** @protected @type {number} */
		this._carpetTexNum = 1;

		/** @protected @type {number} */
		this._carpetRot = 0;

		/** @protected @type {ShaderMaterial} */
		this._shaderMat;

		/** @protected @type {boolean} */
		this._isShaderInitialized = true; // TODO is this correct and really needed? this variable will always be true
		
		// Meshes
		/** @protected @type {Mesh} */
		this._carpet;

		/** @protected @type {Mesh} */
		this._circleRotationHandle;

		const circleRotationHandleTrianglesNumber = 4;

		/** @protected @type {Mesh} */
		this._containerDisc;

		/** @protected @type {boolean} */
		this._meshIsLoading = false;

		/** @protected @type {object} */
		this._currentMesh = {};
		this._currentMesh.scaleX = 0;
		this._currentMesh.scaleZ = 0;
		this._currentMesh.rot = 0;
		this._currentMesh.mesh = 0;
		this._currentMesh.positionX = 0;
		this._currentMesh.positionZ = 0;
		this._currentMesh.rotationY = 0;
		this._currentMesh.texUrl = "";
		
		/** @public */
		const scene = this.scene = new Scene(this.engine);
		scene.useRightHandedSystem = true;
		scene.clearColor = new Color4(0, 0, 0, 0);
		scene.ambientColor = new Color3(0.4, 0.4, 0.4);
		
		if(wantInternalResolution) {
			// store a reference to the original implementation
			const _updatePointerPositionOrig=scene._inputManager._updatePointerPosition;
			scene._inputManager._updatePointerPosition=function(evt) {
				const canvasRect = this._scene.getEngine().getInputElementClientRect();
				
				if (!canvasRect) {
					return;
				}
				
				// first convert to "standard" resolution (that's the same code as
				// in the Scene.InputManager._updatePointerPosition() function)
				const pointerX=evt.clientX-canvasRect.left;
				const pointerY=evt.clientY-canvasRect.top;
				
				// assume that the internal and external resolution have the
				// same aspect ratio
				const renderHeight=this._scene.getEngine().getRenderHeight();
				const scale=renderHeight/canvasRect.height;
				// evt is a PointerEvent. We cannot modify it's clientX and clientY
				// value. As we known that "_updatePointerPositionOrig" only uses
				// these two values, we can simply create a "proxy" object that only
				// provides those values and nothing else. This may have to be adapted
				const scaledEvt={
					clientX: pointerX*scale + canvasRect.left,
					clientY: pointerY*scale + canvasRect.top
				};
				_updatePointerPositionOrig.call(this, scaledEvt);
			}
		}

		/** @protected */
		this._camera = new UniversalCamera("UniversalCamera", new Vector3(0, 0, -10), scene);
		this._camera.maxZ = 100000;
		
		// TODO the above zFar value is not good as it defines a non-metric unit
		// In order to setup a compatible camera that produces identical results
		// as in the Java implementation, use the following values:
		//this._camera.minZ=0.1;
		//this._camera.maxZ=100.0;

		// Materials

		/** @protected */
		this._circleRotationHandleMat = new StandardMaterial("circleRotationHandleMat", scene);
		this._circleRotationHandleMat.diffuseColor = new Color3(0.9, 0.9, 0.9);
		this._circleRotationHandleMat.specularColor = new Color3(0.9, 0.9, 0.9);
		this._circleRotationHandleMat.emissiveColor = new Color3(0.9, 0.9, 0.9);

		/** @protected */
		this._carpetMat = new CustomMaterial("carpet_top", scene);
	
		// There are two textures for the 'carpetMat' for smoother transitions while changing
		/** @protected */
		this._carpetTex1 = new Texture(null, scene);
		/** @protected */
		this._carpetTex2 = new Texture(null, scene);
		this._carpetMat.specularColor = new Color3(0, 0, 0);
		this._carpetMat.diffuseTexture = this._carpetTex1;

		/** @protected */
		this._carpetBottomMat = new StandardMaterial("carpet_bottom", scene);
		/** @protected */
		this._carpetBottomTex = new Texture(null, scene);
		this._carpetBottomMat.diffuseTexture = this._carpetBottomTex;

		/** @protected */
		this._containerDiscMat = new StandardMaterial("containerDiscMat", scene);
		this._containerDiscMat.diffuseColor = new Color3(0.4, 0.4, 0.4);
		this._containerDiscMat.alpha = 0;

		const light = new DirectionalLight("light", new Vector3(0,-1, 0), scene);
		light.position.x = 0;
		light.position.y = 10000;
		light.position.z = 0;

		// this is the default material and should be overwritten if an image mask is set
		/** @protected @type {CustomMaterial} */
		const groundMaterial = new CustomMaterial("mat", scene);
		
		/** @protected @type {Mesh} */
		this._ground = CreatePlane("ground", {size: 1000000}, scene)
		this._ground.rotation.x = (Math.PI / 2);
		this._ground.scaling = new Vector3(10, 10, 1);
		this._ground.receiveShadows = true;
		this._ground.material = groundMaterial;
		this._ground.position.y = -50;

		// Back and Foreground Layer
		/** @protected */
		this._layerBack = new Layer("backLayer", null, scene, true); // TODO this is part of the old layer system and may be removed in the future
		const layerFront = new Layer("frontLayer", null, scene, false); // TODO this is part of the old layer system and may be removed in the future
		
		// There are two textures for the 'layerBack' for smoother transitions while changing
		/** @protected */
		this._layerBackTex1 = new Texture(null, scene);
		/** @protected */
		this._layerBackTex2 = new Texture(null, scene);
		/** @protected */
		this._layerFrontTex = new Texture(null, scene);
		this._layerBack.texture = this._layerBackTex1;
		layerFront.texture = this._layerFrontTex;

		// Shadow
		// the parameter 'mapSize' also affect the value that ist returned from engines 'getRenderWidth' when queried in a observable
		/** @protected @type {ShadowGenerator} */
		this._shadowGenerator = new ShadowGenerator(1024, light, false);
		this._shadowGenerator.useBlurExponentialShadowMap = true;
		this._shadowGenerator.useKernelBlur = true;
		this._shadowGenerator.blurScale = 10;
		this._shadowGenerator.blurKernel = 10;
		// this._shadowGenerator.contactHardeningLightSizeUVRatio = 0.5;
		this._shadowGenerator.setDarkness(0.77);
		this._shadowGenerator.transparencyShadow = true;

		// Triangles for the rotation handle. The mesh at index 0 in the array is a 'template'.
		// This template is used to create more instances via 'createInstance'. See below.
		// To create triangles use the MeshBuilders 'CreateDisc' with a tesselation of 3.
		/** @protected @type {Mesh[]} */
		this._triangleHandles = [];
		this._triangleHandles[0] = CreateDisc("tria_0", {tessellation : 3, radius : 100});
		this._triangleHandles[0].rotation.x = Math.PI;
		this._triangleHandles[0].scaling.y = 0.6;
		this._triangleHandles[0].position.y = 0.5;
		this._triangleHandles[0].material = this._circleRotationHandleMat;

		for (var ti = 1; ti < circleRotationHandleTrianglesNumber; ti++) {
			this._triangleHandles[ti] = this._triangleHandles[0].createInstance("tria_" + ti);
		}

		// for the event listeners we need to correctly pass "this" as those functions are instance
		// methods, otherwise they will not "find" the instance methods or variables. So just
		// create a bound version of those functions. Note: It's important that we use those
		// (different) functions for removeEventListener as well.
		const onPointerDownBound = this._onPointerDown.bind(this);
		canvas.addEventListener("pointerdown", onPointerDownBound, false);

		const onPointerUpBound = this._onPointerUp.bind(this);
		canvas.addEventListener("pointerup", onPointerUpBound, false);

		const onPointerMoveBound = this._onPointerMove.bind(this);
		canvas.addEventListener("pointermove", onPointerMoveBound, false);
		
		scene.onDispose = () => {
			canvas.removeEventListener("pointerdown", onPointerDownBound);
			canvas.removeEventListener("pointerup", onPointerUpBound);
			canvas.removeEventListener("pointermove", onPointerMoveBound);
		}
	}

	/**
	 * @returns {?Vector3} the world coordinates of the ground point with the specified window coordinates
	 * @protected
	 */
	_getGroundPosition(posX, posY) {
		const pickinfo = this.scene.pick(posX, posY, (mesh) => {
			return mesh == this._ground;
		});
	
		if (pickinfo.hit) {
			return pickinfo.pickedPoint;
		}
	
		return null;
	}

	/** @protected */
	_onPointerDown(evt) {
		if (evt.button !== 0) {
			return;
		}
		
		// check if we are under a mesh
		const pickInfo = this.scene.pick(this.scene.pointerX, this.scene.pointerY, (mesh) => {
			if (typeof mesh.e3dPick != "undefined") {
				return true;
			} else {
				return false;
			}
		});

		if (pickInfo.hit) {
			const currentPickedMesh = pickInfo.pickedMesh;

			if (currentPickedMesh.e3dPick == 1) {
				this._rotOrMove = 1;
				this._startingPoint = this._getGroundPosition(this.scene.pointerX, this.scene.pointerY);
				const startRotation = this._calcRot(this._startingPoint.x, this._startingPoint.z, this._containerDisc.position.x, this._containerDisc.position.z);
				this._currentRotation = startRotation - this._containerDisc.rotation.y;
			} else if (currentPickedMesh.e3dPick == 2) {
				this._rotOrMove = 0;
				this._startingPoint = this._getGroundPosition(this.scene.pointerX, this.scene.pointerY);
			}
		}
	}

	/** @protected */
	_onPointerUp() {
		if (this._startingPoint) {
			this._startingPoint = null;
			return;
		}
	}

	/** @protected */
	_onPointerMove(evt) {
		const current = this._getGroundPosition(this.scene.pointerX, this.scene.pointerY);
		this._fadingActivationIdleTime = 0;

		if (!this._startingPoint) {
			return;
		}
		
		if (!current) {
			return;
		}

		this._srot = this._calcRot(current.x, current.z, this._containerDisc.position.x, this._containerDisc.position.z);
		this._srot = this._srot - this._currentRotation;

		if (this._rotOrMove == 0) {
			const diff = current.subtract(this._startingPoint);
			this._containerDisc.position.addInPlace(diff);
			this._currentMesh.positionX = this._containerDisc.position.x;
			this._currentMesh.positionZ = this._containerDisc.position.z;
		} else if (this._rotOrMove == 1) {
			this._containerDisc.rotation.y = this._srot;
			this._currentMesh.rotationY = this._containerDisc.rotation.y;
		}
		
		this._startingPoint = current;
	}

	/** @protected */
	_fadingActivationTimerFunc() {
		if ((this._fadingActivationType == 1) && (this._fadingActivationIdleTime == 0)) {
			this._fadingActivationType = 0;
			this._fadeVal = 0.1;
			this._fader = setInterval(this._fadeFunc.bind(this), 80);
		} else if ((this._fadingActivationType == 0) && (this._fadingActivationIdleTime > 20)) {
			this._fadingActivationType = 1;
			this._fadeVal = -0.1;
			this._fader = setInterval(this._fadeFunc.bind(this), 80);
		}
		
		this._fadingActivationIdleTime++;
	}

	/** @protected */
	_fadeFunc() {
		this._circleRotationHandle.visibility += this._fadeVal;
		this._triangleHandles[0].visibility += this._fadeVal;
		
		if (this._circleRotationHandle.visibility >= 1) {
			this._circleRotationHandle.visibility = 1;
			this._triangleHandles[0].visibility = 1;
			clearInterval(this._fader);
		} else if (this._circleRotationHandle.visibility <= 0) {
			this._circleRotationHandle.visibility = 0;
			this._triangleHandles[0].visibility = 0;
			clearInterval(this._fader);
		}
	}

	setCamera(cvals) {
		this._camera.position.x = cvals.pos_x * 1000;
		this._camera.position.y = cvals.pos_y * 1000;
		this._camera.position.z = cvals.pos_z * 1000;
		this._camera.rotation.x = cvals.pitch;
		this._camera.rotation.y = cvals.heading - Math.PI;
		this._camera.rotation.z = cvals.roll;
		this._camera.fov = IPSCore.degreeToRadians(cvals.fov);

		if(cvals.tile) {
			// this image is calibrated with tile data. We need to use a custom
			// perspective() implementation
			const tile=cvals.tile;
			const renderWidth=this.scene.getEngine().getRenderWidth();
			const renderHeight=this.scene.getEngine().getRenderHeight();
			
			const scaleX=renderWidth/tile.unscaledWidth;
			const scaleY=renderHeight/tile.unscaledHeight;
			// get the current zoom factor. If there is tile data
			// and the zoom factor is not 100%, then we need to rescale
			// the tile data, too.
			const tileScaled={x: tile.x*scaleX, y: tile.y*scaleY,
				width: tile.width*scaleX, height: tile.height*scaleY};

			// safety: our perspective implementation does not support all
			// parameters of the Babylon.js camera, so check now if the
			// current settings are good.
			const engine = this.scene.getEngine();
			const reverseDepth = engine.useReverseDepthBuffer;
			
			if(reverseDepth) throw new Error("reverseDepth not supported");
			if(this._camera.fovMode!==Camera.FOVMODE_VERTICAL_FIXED) throw new Error("fovMode not supported");
			if(this._camera.projectionPlaneTilt!=0) throw new Error("planeTilt not supported");
			
			// the standard camera perspective matrix calculation happens in
			// Camera.getProjectionMatrix() and calls Matrix.PerspectiveFovRHToRef
			// for right-handed systems
//			console.log("cam.projectionMa="+this._camera.getProjectionMatrix());
			
			// the same check as in Camera.getProjectionMatrix()
			if (this._camera.minZ <= 0) {
				this._camera.minZ = 0.1;
			}
			
			let projectionMatrix = new Matrix();
			// note: camera.fov is in radian and perspectiveToRef also uses radians
			// and not degrees as in the standard implementation
			IPSCore.perspectiveToRef(this._camera.fov,
				this.scene.getEngine().getRenderWidth(),
				this.scene.getEngine().getRenderHeight(),
				this._camera.minZ,
				this._camera.maxZ,
				tileScaled,
				engine.isNDCHalfZRange,
				projectionMatrix);
//			console.log("projectionMaTILE="+projectionMatrix);
			
			this._camera.freezeProjectionMatrix(projectionMatrix);
		}
		else this._camera.unfreezeProjectionMatrix(); // activate perspective matrix calculation again
	}

	/**
	 * Implementation of gluPerspective that is able to work correctly with
	 * tiles. It sets up a right-handed perspective projection and stores it in
	 * the given matrix.
	 * <p>
	 * It does not support all of the Babylon.js camera features. It needs to
	 * be checked outside of this function whether the parameters are supported.
	 * 
	 * @param {number} fovy the field of view angle, in radians, in the
	 * 		y-direction
	 * @param {number} width the render width. If no tileData is specified,
	 * 		then it is also used to calculate the aspect ratio
	 * @param {number} height the render height. If no tileData is specified,
	 * 		then it is also used to calculate the aspect ratio
	 * @param {number} zNear the distance from the viewer to the near clipping
	 * 		plane (always positive).
	 * @param {number} zFar the distance from the viewer to the far clipping
	 * 		plane (always positive).
	 * @param {{width: number, height: number, x: number, y: number}} tileData
	 * 		the optional tile data
	 * @param {boolean} halfZRange true to generate NDC coordinates between
	 * 		0 and 1 instead of -1 and 1 (default: false)
	 * @param {Matrix} result defines the target matrix
	 * 
	 * @returns result input
	 */
	static perspectiveToRef(fovy, width, height, zNear, zFar, tileData, halfZRange, result) {
		const aspect=tileData==null ? (width/height) : (tileData.width/tileData.height);
		
		let ymax=zNear*Math.tan(fovy*0.5);
		let ymin=-ymax;
		
		let xmin=ymin*aspect;
		let xmax=ymax*aspect;
		
		if(tileData!=null) {
			// we need to adjust the frustum points to achieve
			// the tile effect.
			// note: we have to invert the tile Y position since
			// this is based on the large image position but
			// the frustum calculation takes this value as bottom value.
			const tileDataY=tileData.height-height-tileData.y;
			
			/* compute projection parameters */
			const left=xmin + (xmax-xmin)*tileData.x / tileData.width;
			const right=left + (xmax-xmin)*width / tileData.width;
			const bottom=ymin + (ymax-ymin)*tileDataY / tileData.height;
			const top=bottom + (ymax-ymin)*height / tileData.height;
			
			xmin=left;
			xmax=right;
			ymin=bottom;
			ymax=top;
		}
		
		return IPSCore.frustumToRef(xmin, xmax, ymin, ymax, zNear, zFar, halfZRange, result);
	}

	/**
	 * Implementation of glFrustum. It sets up a right-handed perspective
	 * projection and stores it in the given matrix.
	 * <p>
	 * It does not support all of the Babylon.js camera features. It needs to
	 * be checked outside of this function whether the parameters are supported.
	 * See {@link Matrix.PerspectiveFovRHToRef} for a different perspective
	 * matrix implementation that supports all Babylon.js features but has no
	 * tile support.
	 * 
	 * @param {number} left the coordinate for the left-vertical clipping plane
	 * @param {number} right the coordinate for the right-vertical clipping
	 * 		plane
	 * @param {number} bottom the coordinate for the bottom-horizontal clipping
	 * 		plane
	 * @param {number} top the coordinate for the bottom-horizontal clipping
	 * 		plane
	 * @param {number} nearval the distances to the near-depth clipping plane.
	 * 		Must be positive.
	 * @param {number} farval the distances to the far-depth clipping planes.
	 * 		Must be positive.
	 * @param {boolean} halfZRange true to generate NDC coordinates between
	 * 		0 and 1 instead of -1 and 1 (default: false)
	 * @param {Matrix} result defines the target matrix
	 * 
	 * @returns result input
	 */
	static frustumToRef(left, right, bottom, top, nearval, farval, halfZRange, result) {
		const x=(2.0*nearval) / (right-left);
		const y=(2.0*nearval) / (top-bottom);
		const a=(right+left) / (right-left);
		const b=(top+bottom) / (top-bottom);
		const c=-(farval+nearval) / ( farval-nearval);
		const d=-(2.0*farval*nearval) / (farval-nearval);
		
		Matrix.FromValuesToRef(
			x, 0.0, 0.0, 0.0,
			0.0, y, 0.0, 0.0 /*rot*/,
			a, b, c, -1.0,
			0.0, 0.0, d, 0.0,
			result);
		
		if (halfZRange) {
			// the variable Matrix.mtxConvertNDCToHalfZRange seems to be private,
			// so we cannot simply refer to it but have to use a copy
			const mtxConvertNDCToHalfZRange = Matrix.FromValues(
				1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0.5, 0, 0, 0, 0.5, 1);
			result.multiplyToRef(mtxConvertNDCToHalfZRange, result);
		}
		
		result._updateIdentityStatus(false);
		
		return result;
	}

	getMesh() {
		return this._currentMesh;
	}

	getToolsVisibility() {
		return this._circleRotationHandle.visibility;
	}

	setToolsVisibility(mVisibilty) {
		this._circleRotationHandle.visibility = mVisibilty;
		this._triangleHandles[0].visibility = mVisibilty;
	}

	setMesh(meshFile, scaleX, scaleZ, rot, useShaderMaterial) {
		this._meshIsLoading = true;
		this._carpetRot = rot;
		
		if ((this._carpetRot != 0) && (this._carpetRot != 1)) {
			this._carpetRot = 0;
		}
		this._currentMesh.scaleX = scaleX;
		this._currentMesh.scaleZ = scaleZ;
		this._currentMesh.rot = this._carpetRot;
		this._currentMesh.mesh = meshFile;

		clearInterval(this._fadingActivationTimer);
		
		for (var ti = 0; ti < this._triangleHandles.length; ti++) {
			this._triangleHandles[ti].setParent(null);
		}

		if (this._circleRotationHandle) {
			this._circleRotationHandle.dispose();
		}
		
		if (this._containerDisc) {
			this._containerDisc.dispose();
		}
		
		for (var i = 0; i < this._emeshes.length; i++) {
			this._emeshes[i].dispose();
		}

		SceneLoader.ImportMesh(null, meshFile, "", this.scene, (meshes, particleSystems, skeletons) => {
			if ((scaleX != 1) || (scaleZ != 1)) {
				for (var i = 0; i < meshes.length; i++) {
					meshes[i].scaling.x = scaleX;
					meshes[i].scaling.z = scaleZ;
				}
			}

			this._emeshes = meshes;

			// The 'meshFile' should ideally contain only one model. However we cannot ensure this.
			// It is also possible that the model is grouped by the 'g' statement. If so the proper
			// model is at index 1 in 'meshes' result.
			if (meshes.length == 1) {
				this._carpet = meshes[0];
				this._carpet.e3dPick = 2;
			} else if (meshes.length == 2) {
				this._carpet = meshes[1];
				this._carpet.e3dPick = 2;
			} else {
				this._carpet = meshes[3];
				meshes[1].material = this._carpetBottomMat;
				meshes[1].e3dPick = 2;
				meshes[1].parent = this._carpet;
			}

			if (useShaderMaterial) {
				// this could return null if not all neccessary images are set yet
				try {
					this._carpet.material = useShaderMaterial();
				} catch (error) {
					console.error(error);
					this._carpet.material = this._carpetMat;
				}
			} else {
				this._carpet.material = this._carpetMat;
			}

			this._carpet.e3dPick = 2;

			this._carpet.width = (this._carpet.geometry.extend.maximum.x - this._carpet.geometry.extend.minimum.x) * scaleX;
			this._carpet.height = (this._carpet.geometry.extend.maximum.z - this._carpet.geometry.extend.minimum.z) * scaleZ;

			this._shadowGenerator.addShadowCaster(this._carpet);

			const dia = Math.sqrt(Math.pow(this._carpet.width, 2) + Math.pow(this._carpet.height, 2)) + 120;

			this._containerDisc = CreateDisc("containerDisc", {radius: (dia / 2), tessellation: 128}, this.scene);
			this._containerDisc.e3dPick = 1;
			this._containerDisc.position.y = -50;
			this._containerDisc.position.z = this._currentMesh.positionZ;
			this._containerDisc.position.x = this._currentMesh.positionX;
			this._containerDisc.rotation.y = this._currentMesh.rotationY;
			this._containerDisc.rotation.x = -(Math.PI / 2);
			this._containerDisc.material = this._containerDiscMat;
			this._carpet.parent = this._containerDisc;
			
			if (this._carpet.name != "IPSCarpet") {
				this.scene.render();
				this._carpet.name = "IPSCarpet";
				setTimeout(() => {
					this.setCarpetPos((this.canvas.width/2), (this.canvas.height/8*5));
				} , 2000);
				this._carpet.position.z = 2;
				this._carpet.rotation.z = (Math.PI / 2);
				this._carpet.rotation.y = (Math.PI * 1.5);
				
				if (this._carpetRot == 0) {
					this._carpet.rotation.x = (Math.PI / 2);
				} else {
					this._carpet.rotation.x = 0;
				}
			}

			// rotation handle
			this._circleRotationHandle = CreateTorus("circleRotationHandle", {diameter: dia, thickness: 10, tessellation: 128}, this.scene);
			this._circleRotationHandle.e3dPick = 1;
			this._circleRotationHandle.material = this._circleRotationHandleMat;
			this._circleRotationHandle.position.y = 0;
			this._circleRotationHandle.position.x = 0;
			this._circleRotationHandle.scaling.y = 0.01;
			this._circleRotationHandle.rotation.x = (Math.PI / 2);
			this._circleRotationHandle.parent = this._containerDisc;

			// append triangle handles to circleRotationHandle
			for (var ti = 0; ti < this._triangleHandles.length; ti++) {
				this._triangleHandles[ti].parent = this._containerDisc;
				this._triangleHandles[ti].position.z = 1;
				this._triangleHandles[ti].visibility = 1;
				this._triangleHandles[ti].rotation.x = Math.PI;
				this._triangleHandles[ti].rotation.y = 0;
			}

			this._triangleHandles[0].rotation.z = (Math.PI / 2);
			this._triangleHandles[0].position.x = (dia / 2) - 4;
			this._triangleHandles[0].position.z = 0;

			this._triangleHandles[1].rotation.z = -(Math.PI / 2);
			this._triangleHandles[1].position.x = -(dia / 2) + 4;
			this._triangleHandles[1].position.z = 0;

			this._triangleHandles[2].position.x = 0;
			this._triangleHandles[2].position.y = (dia / 2) - 4;

			this._triangleHandles[3].rotation.z = Math.PI;
			this._triangleHandles[3].position.x = 0;
			this._triangleHandles[3].position.y = -(dia / 2) + 4;

			if(this._activateRotationHandleFadingTimer) {
				this._fadingActivationTimer = setInterval(this._fadingActivationTimerFunc.bind(this), 100);
				this._fadingActivationIdleTime = 0;
			}
			
			this._meshIsLoading = false;
		});
	}

	setLayer(layer, data) {
		let tex;
		if (layer == "front") {
			this._layerFrontTex.releaseInternalTexture();
			this._layerFrontTex.updateURL(data);
		} else if (layer == "back") {
			if (this._layerBackTexNum == 1) {
				this._layerBackTexNum = 2;
				tex = this._layerBackTex2;
			} else {
				this._layerBackTexNum = 1;
				tex = this._layerBackTex1;
			}
			tex.releaseInternalTexture();
			tex.updateURL(data, null, this._changeLayerBackTex.bind(this));
		} else {
			return;
		}
	}

	resetLayer(layer) {
		let tex;
		if (layer == "front") {
			this._layerFrontTex.releaseInternalTexture();
		} else if (layer == "back") {
			if (this._layerBackTexNum == 1) {
				this._layerBackTexNum = 2;
				tex = this._layerBackTex2;
				this._changeLayerBackTex();
			} else {
				this._layerBackTexNum = 1;
				tex = this._layerBackTex1;
				this._changeLayerBackTex();
			}
			tex.releaseInternalTexture();
		} else {
			return;
		}
	}

	/** @protected */
	_changeLayerBackTex() {
		if (this._layerBackTexNum == 1) {
			this._layerBack.texture = this._layerBackTex1;
		} else {
			this._layerBack.texture = this._layerBackTex2;
		}
	}

	resetCarpet() {
		// hides the carpet mesh
		if (this._containerDisc) {
			this._containerDisc.setEnabled(false);
		}
	}

	setCarpetPos(posx, posy) {
		if (this._emeshes.length > 0) {
			const startPos = this._getGroundPosition(posx, posy);
			this._currentMesh.positionX = startPos.x;
			this._currentMesh.positionZ = startPos.z;
			
			if (!this._meshIsLoading) {
				this._containerDisc.position.x = startPos.x;
				this._containerDisc.position.z = startPos.z;
			}
		}
	}

	setCarpetPosition(posx, posz) {
		this._currentMesh.positionX = posx;
		this._currentMesh.positionZ = posz;
	}

	setCarpetRot(rot) {
		this._currentMesh.rotationY = rot;
	}

	setCarpetTex(data) {
		let tex;
		if (this._carpetTexNum == 1) {
			this._carpetTexNum = 2;
			tex = this._carpetTex2;
		} else {
			this._carpetTexNum = 1;
			tex = this._carpetTex1;
		}
		tex.releaseInternalTexture();
		tex.updateURL(data, null, this._changeCarpetTex.bind(this));
		this._currentMesh.texUrl = data;
	}

	getCarpetTex() {
		if (this._carpetTexNum == 1) {
			return this._carpetTex1;
		}
		else if (this._carpetTexNum == 2) {
			return this._carpetTex2;
		}

		return null;
	}
	
	/** @protected */
	_changeCarpetTex() {
		if (this._carpetTexNum == 1) {
			this._carpetMat.diffuseTexture = this._carpetTex1;
		} else {
			this._carpetMat.diffuseTexture = this._carpetTex2;
		}
		
		if (this._carpetRot == 1) {
			this._carpetMat.diffuseTexture.wAng = Math.PI / 2;
		} else {
			this._carpetMat.diffuseTexture.wAng = 0;
		}
		if (this._isShaderInitialized && this._shaderMat) {
			this._shaderMat.setTexture("carpetTex", this.getCarpetTex());
		}
	}

	setCarpetBottomTex(data) {
		this._carpetBottomTex.releaseInternalTexture();
		this._carpetBottomTex.updateURL(data);
	}

	/** @protected */
	_calcRot(cx, cz, ox, oz) {
		const ankat = Math.abs(cx - ox);
		const gekat = Math.abs(cz - oz);
		let rot = Math.atan(ankat / gekat);
		
		if ((cx >= ox) && (cz >= oz)) {
			rot = Math.PI + rot;
		} else if ((cx < ox) && (cz >= oz)) {
			rot = Math.PI - rot;
		} else if ((cx >= ox) && (cz < oz)) {
			rot = (Math.PI * 2) - rot;
		} else {
			this._srot = this._srot;
		}

		return rot;
	}

	static degreeToRadians($degree) {
		return $degree * Math.PI / 180;
	}

	static radiansToDegrees($radian) {
		return $radian * 180 / Math.PI;
	}
	
	setMaskImage(image, callback) {
		this._maskImage = new Texture(image, this.scene, true, true, Texture.NEAREST_SAMPLINGMODE, null, null);
		if(callback) callback();
	}

	getMaskImage() {
		if(this._maskImage) return this._maskImage;
		return null;
	}
	
	setGroundMaterialForShadowClipping = () => {
		this._ground.material.dispose();
		const groundMaterial = new CustomMaterial("customShadowOnlyMaterial", this.scene);
		
		groundMaterial.Fragment_MainBegin(`
			vec2 coord = gl_FragCoord.xy/u_resolution;
			vec4 maskColor = texture2D(maskImage, coord);
		`);
		
		groundMaterial.Fragment_Before_FragColor(`
			if (maskColor.a == 0.) {
				return;
			}
		
			// draw shadow
			if (shadow < 1.) {
				color = vec4(0., 0., 0., 1. - shadow);
			} else {
				discard;
			}
		`);
		
		groundMaterial.shadowDepthWrapper = new ShadowDepthWrapper(groundMaterial);
		groundMaterial.AddUniform("maskImage", "sampler2D");
		groundMaterial.AddUniform("u_resolution", "vec2");
		groundMaterial.needAlphaBlending = () => true;

		groundMaterial.onBindObservable.add(() => { 
			groundMaterial.getEffect().setTexture("maskImage", this.getMaskImage());
			groundMaterial.getEffect().setVector2("u_resolution", new Vector2(this.scene.getEngine().getRenderWidth(), this.scene.getEngine().getRenderHeight()));
		});
		
		this._ground.material = groundMaterial;
	}

	// Create ShaderMaterial
	shaderMaterialForLightAndShadow = () => {
		if (this.getMaskImage() && this.getCarpetTex() != null) {
			this._shaderMat = new ShaderMaterial("overlayMask", this.scene, {
				vertexSource: overlayMaskVertexShaderSource,
				fragmentSource: overlayMaskFragmentShaderSource
			}, {
				attributes: ["position", "normal", "uv"],
				uniforms: ["world", "view", "projection", "u_resolution"],
				samplers: ["carpetTex", "maskImage"],
				needAlphaBlending: true,
				needAlphaTesting: false
			});
		
			this._shaderMat.setTexture("carpetTex", this.getCarpetTex());
			this._shaderMat.setTexture("maskImage",  this.getMaskImage());
			this._shaderMat.setVector2("u_resolution", new Vector2(this.scene.getEngine().getRenderWidth(), this.scene.getEngine().getRenderHeight()));
			this._isShaderInitialized = true;
			
			return this._shaderMat;

		} else {
			throw new Error("missing image/sampler for 'shaderMaterialForLightAndShadow' shader");
		}
	}
}
